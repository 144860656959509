import { AUTH } from "../constants/actionTypes";
import * as api from "../api/index.js";
import { message } from "antd";

export const signin = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);
    dispatch({ type: AUTH, data });
    if (data.result._id) {
      history("/dashboard");
      message.success("Sign In successfully");
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
    }
  }
};

export const signup = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);
    dispatch({ type: AUTH, data });
    if (data) {
      history("/");
      message.success("Account created, please login");
    }
  } catch (error) {
    if (error.response) {
      if (error.response) {
        console.log(error.response.data.message);
        message.error(error.response.data.message);
      }
    }
  }
};
