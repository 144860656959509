import React, { useState, useEffect, useRef } from "react";
import { Avatar, Button } from "@material-ui/core";
import useStyles from "../../styles";
import * as actionType from "../../constants/actionTypes";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import { Link } from "react-router-dom";

const Navbar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    history("/");
    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const current = location?.pathname;

  return (
    <div className="mt-2">
      <nav className="bg-gray-800">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  className="h-8 w-8"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                  alt="Workflow"
                />
              </div>
              <div className="md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <Link
                    to="/dashboard"
                    className={`${
                      current === "/dashboard"
                        ? "text-white bg-gray-900 "
                        : "text-gray-300 hover:bg-gray-700 hover:text-white"
                    }block px-3 py-2 rounded-md font-medium`}
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/leads"
                    className={`${
                      current === "/leads"
                        ? "text-white bg-gray-900 "
                        : "text-gray-300 hover:bg-gray-700 hover:text-white"
                    }block px-3 py-2 rounded-md font-medium`}
                  >
                    Leads
                  </Link>
                </div>
              </div>
            </div>
            <div className="md:block">
              <div className="flex items-center md:ml-6">
                <div className="relative">
                  <div>
                    <Button
                      ref={anchorRef}
                      aria-controls={open ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                    >
                      <Avatar
                        className={`${classes.purple} h-8 w-8 rounded-full`}
                        alt={user?.result?.name}
                        src={user?.result?.imageUrl}
                      >
                        {user?.result?.name?.charAt(0)}
                      </Avatar>
                    </Button>
                    <Popper
                      style={{ zIndex: 10 }}
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      placement={"bottom-end"}
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                              >
                                <div className="text-center p-6  border-b">
                                  <div className="mr-2 ">
                                    <span className="ant-avatar ant-avatar-lg ant-avatar-circle ant-avatar-image bg-primary">
                                      <Avatar
                                        className={`${classes.purple} h-8 w-8 rounded-full mx-auto`}
                                        alt={user.result.name}
                                        src={user.result.imageUrl}
                                      >
                                        {user.result.name.charAt(0)}
                                      </Avatar>{" "}
                                    </span>
                                  </div>
                                  <div className="text-md font-semibold">
                                    {user?.result?.name}
                                  </div>
                                  <div className="text-sm mb-4 text-gray-500">
                                    {user?.result?.email}
                                  </div>
                                </div>
                                <div className="px-4 py-2">
                                  <div className="uppercase text-xs font-semibold text-gray-500">
                                    Your Account
                                  </div>
                                </div>
                                <div>
                                  <MenuItem className="px-4 py-2 hover:bg-gray-100 flex items-center">
                                    <div className="text-gray-600">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                      >
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                        <path
                                          fillRule="evenodd"
                                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                                        />
                                      </svg>
                                    </div>
                                    <p className="ml-3 text-sm font-medium text-gray-800 leading-none mb-0 p-2">
                                      My profile
                                    </p>
                                  </MenuItem>
                                </div>
                                <div>
                                  <MenuItem
                                    className="px-4 py-2 pb-4 bg-gray-100 flex items-center"
                                    onClick={logout}
                                  >
                                    <span
                                      role="img"
                                      aria-label="logout"
                                      className="anticon anticon-logout"
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        className
                                        data-icon="logout"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 01-112.7 75.9A352.8 352.8 0 01512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 01-112.7-75.9 353.28 353.28 0 01-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 000-12.6z" />
                                      </svg>
                                    </span>
                                    <p className="ml-3 text-sm font-medium text-gray-800 leading-none mb-0 p-2">
                                      Log out
                                    </p>
                                  </MenuItem>
                                </div>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
