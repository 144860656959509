import {
  FETCH_ALL,
  CREATE,
  UPDATE,
  DELETE,
  FETCH,
} from "../constants/actionTypes";

export default (leads = [], action) => {
  switch (action.type) {
    case DELETE:
      return leads.filter((lead) => lead._id !== action.payload);
    case UPDATE:
      return leads.map((lead) =>
        lead?._id === action?.payload._id ? action?.payload : lead
      );
    case FETCH_ALL:
      return action.payload;
    case FETCH:
      return action.payload;
    case CREATE:
      return [...leads, action.payload];
    default:
      return leads;
  }
};
