import * as api from "../api";
import {
  FETCH_ALL,
  FETCH,
  CREATE,
  UPDATE,
  DELETE,
  FETCH_USERS,
} from "../constants/actionTypes";
import { message } from "antd";

export const getLeads = (status, source) => async (dispatch) => {
  try {
    const { data } = await api.getLeads(status, source);
    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const createLead = (leadData, history) => async (dispatch) => {
  try {
    const { data } = await api.createLead(leadData);
    dispatch({ type: CREATE, data });
    if (data.success === true) {
      history("/leads");
      message.success("Lead added successfully");
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.data.error.message);
      message.error(error.response.data.error.message);
    }
  }
};

export const updateLead = (leadId, leadData) => async (dispatch) => {
  try {
    const { data } = await api.updateLead(leadId, leadData);
    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    if (error.response) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
    }
  }
};

export const deleteLead = (id) => async (dispatch) => {
  try {
    await api.deleteLead(id);
    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error);
  }
};

export const getLead = (id) => async (dispatch) => {
  try {
    const { data } = await api.getLead(id);
    dispatch({ type: FETCH, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getUsersCount = () => async (dispatch) => {
  try {
    const { data } = await api.getUsersCount();
    dispatch({ type: FETCH_USERS, payload: data });
  } catch (error) {
    console.log(error);
  }
};
