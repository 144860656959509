import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Container,
  Typography,
  Link,
  Button,
  Avatar,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
} from "@material-ui/core";
import Navbar from "../Navbar/Navbar";
import { getLeads, deleteLead } from "../../actions/posts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Spin, Tooltip, Table, message, Space } from "antd";
import { dataSource, dataStatus } from "../../utils/Utils";
import { CloseOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

const Leads = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [source, setSource] = useState("");

  const dispatch = useDispatch();
  const history = useNavigate();

  const data = useSelector((state) => state.leads)?.data?.leadList?.reverse();
  const user = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    dispatch(getLeads(status, source));
    setLoading(false);
  }, [status, source]);

  const onclick = (id) => {
    history(`/leads/view/${id}`);
  };

  const onDelete = (id) => {
    dispatch(deleteLead(id));
    dispatch(getLeads(status, source));
    message.success("Lead Deleted");
  };

  const funRemoveFilter = () => {
    setStatus("");
    setSource("");
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: fullName =>
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <Avatar className="h-10 w-10 rounded-full">
              {fullName?.charAt(0)}
            </Avatar>
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {fullName}
            </div>
          </div>
        </div>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: email => <Paragraph
        className="text-sm text-gray-500"
        copyable={{
          text: `${email}`,
        }}
      >
        {email}
      </Paragraph>
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: source => <div className="text-sm text-gray-500">
        {dataSource?.filter(
          (word) => word?.name === source
        )[0]?.value || "N/A"}
      </div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => <span className="mx-1 uppercase px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {dataStatus?.filter(
          (word) => word?.name === status
        )[0]?.value || "N/A"}
      </span>
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format(
        "Do MMMM YYYY"
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'user',
      key: 'user',
      render: user => <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <Avatar className="h-10 w-10 rounded-full">
            {user?.name?.charAt(0)}
          </Avatar>
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {user?.name}
          </div>
        </div>
      </div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Space size="middle">
            <a onClick={() => onclick(record?._id)}> View </a>
          </Space>
          {user?.result?._id === record?.user._id ? (
            <Space size="middle" className="ml-3">
              <a onClick={() => onDelete(record?._id)}> Delete </a>
            </Space>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <Navbar />
      <Container>
        <div className="max-w-8xl mx-auto">
          <div className="flex items-center justify-between max-w-7xl mx-auto sm:py-6 sm:px-4">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">Leads</h1>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Typography color="textPrimary">Leads</Typography>
              </Breadcrumbs>
            </div>
            <Button variant="contained" color="primary" href="/leads/new">
              Add Lead
            </Button>
          </div>
        </div>
        <div className="m-0">
          <div className="rounded shadow border bg-white px-6 mb-2">
            <Grid
              container
              spacing={3}
              justifyContent="flex-end"
              style={{ marginBottom: 10 }}
            >
              <Grid
                item
                xs={12}
                sm={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 100 }}
                  style={{ minWidth: "100%" }}
                >
                  <InputLabel>Search by Source</InputLabel>
                  <Select
                    onChange={(e) => setSource(e.target.value)}
                    value={source}
                  >
                    {dataSource?.map((data) => {
                      return (
                        <MenuItem value={data?.name} key={data?.id}>
                          {data?.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 100 }}
                  style={{ minWidth: "100%" }}
                >
                  <InputLabel>Search by Status</InputLabel>
                  <Select
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  >
                    {dataStatus?.map((data) => {
                      return (
                        <MenuItem value={data?.name} key={data?.id}>
                          {data?.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: 20 }}
              >
                <Tooltip title="Remove filter">
                  <CloseOutlined
                    onClick={funRemoveFilter}
                    className="cursor-pointer"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </div>
          {data?.length > 0 && loading === false ? (
            <Table columns={columns} dataSource={data} pagination={true} className="w-100" scroll={{ x: 1180 }} />
          ) : (
            <div className="flex justify-center flex-col items-center w-full">
              <Spin />
              <p>Loading...</p>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Leads;
