import * as api from "../api";
import {
  CREATECOMMENT,
  FETCHCOMMENTS,
  DELETECOMMENT,
  UPDATECOMMENT,
} from "../constants/actionTypes";
import { message } from "antd";

export const createComment = (id, commentData) => async (dispatch) => {
  try {
    const { data } = await api.createComment(id, commentData);
    dispatch({ type: CREATECOMMENT, data });
  } catch (error) {
    if (error.response) {
      console.log(error.response.data.error.message);
      message.error(error.response.data.error.message);
    }
  }
};

export const getComments = (id) => async (dispatch) => {
  try {
    const { data } = await api.getComments(id);
    dispatch({ type: FETCHCOMMENTS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteComment = (id, commentID) => async (dispatch) => {
  try {
    await api.deleteComment(id, commentID);
    dispatch({ type: DELETECOMMENT, payload: id, commentID });
  } catch (error) {
    console.log(error);
  }
};

export const updateComment = (id, commentID) => async (dispatch) => {
  try {
    const { data } = await api.updateComment(id, commentID);
    dispatch({ type: UPDATECOMMENT, payload: data });
  } catch (error) {
    console.log(error);
  }
};
