import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Auth from "./components/Auth/Auth";
import Leads from "./components/Lead/Leads";
import AddLead from "./components/Lead/AddLead";
import ViewLead from "./components/Lead/ViewLead";
import EditLead from "./components/Lead/EditLead";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Auth />} />
        <Route path="/dashboard" exact element={<Dashboard />} />
        <Route path="/leads" exact element={<Leads />} />
        <Route path="/leads/new" exact element={<AddLead />} />
        <Route path="/leads/view/:id" exact element={<ViewLead />} />
        <Route path="/leads/edit/:id" exact element={<EditLead />} />
      </Routes>
    </Router>
  );
};

export default App;
