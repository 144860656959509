export const dataTimeline = [
  {
    id: 1,
    name: "1month",
    value: "1 Month",
  },
  {
    id: 2,
    name: "3months",
    value: "3 Months",
  },
  {
    id: 3,
    name: "6months",
    value: "6 Months",
  },
  {
    id: 4,
    name: "1year",
    value: "1 Year",
  },
  {
    id: 5,
    name: "ongoing",
    value: "On-going",
  },
];

export const dataSource = [
  {
    id: 1,
    name: "websiteForm",
    value: "Website Form",
  },
  {
    id: 2,
    name: "webflowForm",
    value: "Webflow Form",
  },
  {
    id: 3,
    name: "directEmail",
    value: "Direct Email",
  },
];

export const dataPriority = [
  {
    id: 1,
    name: "low",
    value: "Low",
    color: 'rgb(50, 205, 50)'
  },
  {
    id: 2,
    name: "medium",
    value: "Medium",
    color: 'rgb(255, 69, 0)'
  },
  {
    id: 3,
    name: "high",
    value: "High",
    color: 'rgb(220, 20, 60)'
  },
];

export const dataStatus = [
  {
    id: 1,
    name: "new",
    value: "New",
  },
  {
    id: 2,
    name: "won",
    value: "Won",
  },
  {
    id: 3,
    name: "lost",
    value: "Lost",
  },
  {
    id: 4,
    name: "hot",
    value: "Hot",
  },
  {
    id: 5,
    name: "cold",
    value: "Cold",
  },
  {
    id: 6,
    name: "potential",
    value: "Potential",
  },
  {
    id: 7,
    name: "contacted",
    value: "Contacted",
  },
  { id: 8, name: "interested", value: "Interested" },
  { id: 9, name: "underReview", value: "Under Review" },
];
