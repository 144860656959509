import {
  CREATECOMMENT,
  UPDATECOMMENT,
  DELETECOMMENT,
  FETCHCOMMENTS,
} from "../constants/actionTypes";

export default (leadComments = [], action) => {
  switch (action.type) {
    case DELETECOMMENT:
      return leadComments?.filter(
        (comment) => comment._id !== action.commentID
      );
    case UPDATECOMMENT:
      return leadComments?.map((comment) =>
        comment?._id === action?.payload._id ? action?.payload : comment
      );
    case CREATECOMMENT:
      return [...leadComments, action.data.data];
    case FETCHCOMMENTS:
      return action.payload.data;
    default:
      return leadComments;
  }
};
