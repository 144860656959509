export const CREATE = "CREATE";
export const DELETE = "DELETE";
export const UPDATE = "UPDATE";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH = "FETCH";

export const CREATECOMMENT = "CREATECOMMENT";
export const DELETECOMMENT = "DELETECOMMENT";
export const UPDATECOMMENT = "UPDATECOMMENT";
export const FETCHCOMMENTS = "FETCHCOMMENTS";

export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const FETCH_USERS = "FETCH_USERS";
