import axios from "axios";

const API = axios.create({ baseURL: "https://lead-backend.onrender.com/api" });
// const API = axios.create({ baseURL: "http://localhost:5000/api" });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("profile")).token
      }`;
  }

  return req;
});

export const getLeads = (status, source) =>
  API.get(
    `/leads/?status=${status ? status : ""}&source=${source ? source : ""}`
  );
export const getUsersCount = () => API.get("/auth/users");
export const createLead = (newLead) => API.post("/leads", newLead);
export const createComment = (id, newComment) =>
  API.post(`/leads/${id}/comment`, newComment);
export const getLead = (id) => API.get(`/leads/${id}`);
export const updateLead = (leadId, leadData) =>
  API.patch(`/leads/${leadId}`, leadData);
export const deleteLead = (id) => API.delete(`/leads/${id}`);
export const getComments = (id) => API.get(`/leads/${id}/comments`);
export const deleteComment = (id, commentID) =>
  API.delete(`/leads/${id}/comment/${commentID}`);
export const updateComment = (id, commentID, updatedComment) =>
  API.put(`/leads/${id}/comment/${commentID}`, updatedComment);

export const signIn = (formData) => API.post("/auth/login", formData);
export const signUp = (formData) => API.post("/auth/register", formData);
