import {
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Input from "../Auth/Input";
import Navbar from "../Navbar/Navbar";
import { updateLead, getLead } from "../../actions/posts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  dataTimeline,
  dataSource,
  dataPriority,
  dataStatus,
} from "../../utils/Utils";
import { message } from "antd";

const EditLead = () => {
  const countries = require("../../utils/countries.json");
  const currency = require("../../utils/currency.json");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const id = location?.pathname.slice(12);
  const lead = useSelector((state) => state.leads);
  const data = lead?.data?.lead;

  const [postData, setPostData] = useState({
    fullName: "",
    email: "",
    phone: "",
    budget: "",
    lookingFor: "",
    city: "",
    state: "",
    country: "",
    timeline: "",
    currency: "",
    description: "",
    source: "",
    priority: "",
    status: "",
  });

  useEffect(() => {
    if (data) setPostData(data);
  }, [data]);

  const handleChange = (e) => {
    setPostData({
      ...postData,
      [e.target.name]: e.target.value,
    });
  };

  // const currentUser = JSON.parse(localStorage.getItem("profile"));

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateLead(id, { ...postData }, history));
    message.success("Lead updated successfully");
    history("/leads");
  };

  useEffect(() => {
    dispatch(getLead(id));
  }, []);
  console.log('postData', postData)
  return (
    <div>
      <Navbar />
      <Container>
        <div className="max-w-8xl mx-auto">
          <div className=" max-w-7xl mx-auto sm:py-6 sm:px-4">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">Edit Lead</h1>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Link color="inherit" href="/leads">
                  Leads
                </Link>
                <Typography color="textPrimary">{data?.fullName}</Typography>
              </Breadcrumbs>
            </div>
          </div>
        </div>
        <main className="m-0">
          <div className="rounded shadow border bg-white px-6 py-4 flex md:flex-row justify-between items-center space-y-4 md:space-x-4">
            <div>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div>
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Profile
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">
                          Name, email, phone number & lead Status
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow sm:rounded-md sm:overflow-hidden px-4 py-5 bg-white sm:p-6">
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Input
                              name="fullName"
                              label="Full Name"
                              handleChange={handleChange}
                              fullWidth
                              value={postData.fullName}
                              autoFocus
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Input
                              name="email"
                              label="Email"
                              handleChange={handleChange}
                              fullWidth
                              value={postData.email}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="phone"
                              label="Phone"
                              type="number"
                              value={postData?.phone}
                              onChange={handleChange}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 100 }}
                              style={{ minWidth: "100%" }}
                            >
                              <InputLabel>Status *</InputLabel>
                              <Select
                                name="status"
                                label="Status"
                                onChange={handleChange}
                                value={postData.status}
                              >
                                {dataStatus?.map((data) => {
                                  return (
                                    <MenuItem value={data?.name} key={data?.id}>
                                      {data?.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden sm:block" aria-hidden="true">
                  <div className="py-5">
                    <div className="border-t border-gray-200" />
                  </div>
                </div>
                <div className="mt-10 sm:mt-0">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Location
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">
                          Address details like city, state and country for
                          TimeZone.
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <Input
                                name="city"
                                label="City"
                                value={postData?.city ? postData?.city : "not given"}
                                handleChange={handleChange}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Input
                                name="state"
                                label="State"
                                value={postData?.state ? postData?.state : "not given"}
                                handleChange={handleChange}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                variant="standard"
                                sx={{ m: 1, minWidth: 100 }}
                                style={{ minWidth: "100%" }}
                              >
                                <InputLabel>Country *</InputLabel>
                                <Select
                                  name="country"
                                  onChange={handleChange}
                                  label="Country"
                                  value={postData.country}
                                >
                                  {countries.map((c, id) => {
                                    return (
                                      <MenuItem value={c.name} key={id}>
                                        {c.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                variant="standard"
                                sx={{ m: 1, minWidth: 100 }}
                                style={{ minWidth: "100%" }}
                              >
                                <InputLabel>Timeline *</InputLabel>
                                <Select
                                  name="timeline"
                                  label="Timeline"
                                  onChange={handleChange}
                                  value={postData.timeline}
                                >
                                  {dataTimeline?.map((data) => {
                                    return (
                                      <MenuItem
                                        value={data?.name}
                                        key={data?.id}
                                      >
                                        {data?.value}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden sm:block" aria-hidden="true">
                  <div className="py-5">
                    <div className="border-t border-gray-200" />
                  </div>
                </div>
                <div className="mt-10 sm:mt-0">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          More details
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">
                          Looking for source, currency, budget, priority,
                          revenue, note, comments.
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                              <Input
                                name="lookingFor"
                                label="Looking For"
                                handleChange={handleChange}
                                fullWidth
                                value={postData.lookingFor}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                variant="standard"
                                sx={{ m: 1, minWidth: 100 }}
                                style={{ minWidth: "100%" }}
                              >
                                <InputLabel>Currency *</InputLabel>
                                <Select
                                  name="currency"
                                  onChange={handleChange}
                                  label="Country"
                                  value={postData.currency}
                                >
                                  {currency.map((c, id) => {
                                    return (
                                      <MenuItem value={c.cc} key={id}>
                                        {c.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                name="budget"
                                label="Lead Budget"
                                fullWidth
                                value={postData.budget}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
                              <FormControl component="fieldset">
                                <FormLabel
                                  component="legend"
                                  style={{ marginBottom: 10 }}
                                >
                                  Source *
                                </FormLabel>
                                <RadioGroup
                                  aria-label="source"
                                  name="source"
                                  onChange={handleChange}
                                  value={postData.source}
                                >
                                  {dataSource.map((data) => {
                                    return (
                                      <FormControlLabel
                                        value={data?.name}
                                        key={data?.id}
                                        control={<Radio />}
                                        label={data?.value}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
                              <FormControl component="fieldset">
                                <FormLabel
                                  component="legend"
                                  style={{ marginBottom: 10 }}
                                >
                                  Priority *
                                </FormLabel>
                                <RadioGroup
                                  aria-label="priority"
                                  name="priority"
                                  onChange={handleChange}
                                  value={postData.priority}
                                >
                                  {dataPriority.map((data) => {
                                    return (
                                      <FormControlLabel
                                        value={data?.name}
                                        key={data?.id}
                                        control={<Radio />}
                                        label={data?.value}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                name="description"
                                label="Note"
                                onChange={handleChange}
                                fullWidth
                                rows={6}
                                multiline
                                value={postData.description}
                                required
                              />
                            </Grid>
                          </Grid>
                          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              type="submit"
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </Container>
    </div>
  );
};

export default EditLead;
