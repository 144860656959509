import React, { useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumbs,
  Container,
  Typography,
  Button,
  Link,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Navbar from "../Navbar/Navbar";
import { getLead, deleteLead, updateLead } from "../../actions/posts";
import {
  createComment,
  getComments,
  deleteComment,
} from "../../actions/comments";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { message, Modal, Spin } from "antd";
import { SendOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  dataTimeline,
  dataSource,
  dataPriority,
  dataStatus,
} from "../../utils/Utils";
import Paragraph from "antd/lib/typography/Paragraph";

const ViewLead = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const id = location?.pathname.slice(12);
  const data = useSelector((state) => state.leads)?.data?.lead;

  useEffect(() => {
    dispatch(getLead(id));
    dispatch(getComments(id));
    setLoading(false);
  }, [comment]);

  const user = JSON.parse(localStorage.getItem("profile"));
  const commentsData = useSelector((state) => state.leadComments);

  const deleteLeadFun = () => {
    dispatch(deleteLead(id));
    history("/leads");
    message.success("Lead Deleted");
  };

  const deleteCommentFun = (commentID) => {
    dispatch(deleteComment(id, commentID));
    message.success("Comment deleted");
  };

  const submitComment = (e) => {
    e.preventDefault();
    dispatch(
      createComment(id, {
        comment,
        user: user?.result?._id,
      })
    );
    message.success("Comment added");
    setTimeout(() => {
      dispatch(getComments(id));
    }, 200);
  };

  const [postData, setPostData] = useState({
    fullName: "",
    email: "",
    phone: "",
    budget: "",
    lookingFor: "",
    city: "",
    state: "",
    country: "",
    timeline: "",
    currency: "",
    description: "",
    source: "",
    priority: "",
    status: "",
  });

  const handleChange = (e) => {
    setPostData({
      ...postData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data) setPostData(data);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateLead(id, { ...postData }));
    message.success("Lead updated successfully");
    setIsModalOpen(false);
    dispatch(getLead(id));
  };

  return (
    <div>
      <Navbar />
      <Container>
        <div className="max-w-8xl mx-auto">
          <div className="flex items-center justify-between max-w-7xl mx-auto sm:py-6 sm:px-4">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">View Lead</h1>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Link color="inherit" href="/leads">
                  Leads
                </Link>
                <Typography color="textPrimary">
                  {data?.fullName || "N/A"}
                </Typography>
              </Breadcrumbs>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                href={`/leads/edit/${id}`}
              >
                Edit
              </Button>
              &nbsp;
              {user?.result?._id === data?.user._id ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setIsModalVisible(true)}
                >
                  Delete
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {data && loading === false ?
          <main>
            <div className="rounded shadow border bg-white p-4 space-y-4 md:space-x-4">
              {data && loading === false ? (
                <div className="relative rounded-xl overflow-auto">
                  <div className="grid sm:grid-cols-3 gap-4">
                    <div className="sm:col-span-1 col-span-2 w-100 bg-white rounded-lg">
                      <div
                        style={{
                          backgroundImage:
                            "radial-gradient(transparent 28px,#ffffff 28px,#ffffff 32px, transparent 32px)",
                          height: 150,
                          width: "100%",
                          backgroundColor: "#00b5f7",
                          backgroundSize: "53px 53px",
                        }}
                        className="rounded-t-lg"
                      />
                      <div>
                        <div
                          className="text-center"
                          style={{ marginTop: "-44px" }}
                        >
                          <span className="border-4 border-white rounded-full mx-auto inline-block">
                            <Avatar
                              className="rounded-full"
                              style={{ width: 80, height: 80 }}
                            >
                              {data?.fullName?.charAt(0)}
                            </Avatar>
                          </span>
                        </div>
                        <h3 className="text-center text-lg leading-6">
                          {data?.fullName || "N/A"}
                        </h3>
                        <p className="text-center mb-0">
                          Added {moment(data?.createdAt).fromNow()} by{" "}
                          {data?.user?._id === user?.result?._id
                            ? "You"
                            : data?.user?.name}
                        </p>
                        <p className="text-center">
                          Last modified {moment(data?.updatedAt).fromNow()} by{" "}
                          {data?.user?._id === user?.result?._id
                            ? "You"
                            : data?.user?.name}
                        </p>
                        <hr />
                        <div className="flex justify-between p-5">
                          <div className="text-center">
                            <p className="font-bold">{data?.budget || "N/A"}</p>
                            <p className="text-xs">Budget</p>
                          </div>
                          <div className="text-center">
                            <p className="font-bold">{data?.currency || "N/A"}</p>
                            <p className="text-xs">Currency</p>
                          </div>
                          <div className="text-center">
                            <p
                              className="font-bold uppercase px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                              style={{ marginBottom: 15 }}
                            >
                              {dataStatus?.filter(
                                (word) => word?.name === data?.status
                              )[0]?.value || "N/A"}
                            </p>
                            <div className="flex items-center justify-center" onClick={() => setIsModalOpen(true)}>
                              <p className="text-xs m-0 pr-2">Status</p><EditOutlined className="cursor-pointer" />
                            </div>
                            <Modal footer={null} title="Update status" visible={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>
                              <form onSubmit={handleSubmit} autoComplete="off">
                                <FormControl
                                  variant="standard"
                                  sx={{ m: 1, minWidth: 100 }}
                                  style={{ minWidth: "100%" }}
                                >
                                  <InputLabel>Status *</InputLabel>
                                  <Select
                                    name="status"
                                    label="Status"
                                    onChange={handleChange}
                                    value={postData.status}
                                  >
                                    {dataStatus?.map((data) => {
                                      return (
                                        <MenuItem value={data?.name} key={data?.id}>
                                          {data?.value}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <FormControl
                                  variant="standard"
                                  sx={{ m: 1, minWidth: 100 }}
                                  style={{ minWidth: "100%", marginTop: 20 }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                  >
                                    Update
                                  </Button>
                                </FormControl>

                              </form>
                            </Modal>
                          </div>
                        </div>
                        <div className="p-5 border bg-gray-100 my-4">
                          <h3>Lead Details</h3>
                          <div className="border-b flex justify-between align-center">
                            <p className="text-xs text-left p-2 py-4 mb-0 font-bold">
                              Looking for
                            </p>
                            <p className="text-xs text-left p-2 py-4 mb-0">
                              {data?.lookingFor || "N/A"}
                            </p>
                          </div>
                          <div className="border-b flex justify-between">
                            <p className="text-xs text-left p-2 py-4 mb-0 font-bold">
                              Timeline
                            </p>
                            <p className="text-xs text-left p-2 py-4 mb-0">
                              {dataTimeline?.filter(
                                (word) => word?.name === data?.timeline
                              )[0]?.value || "N/A"}
                            </p>
                          </div>
                          <div className="border-b flex justify-between">
                            <p className="text-xs text-left p-2 py-4 mb-0 font-bold">
                              Priority
                            </p>
                            <p className="text-xs text-left p-2 py-4 mb-0">
                              {dataPriority?.filter(
                                (word) => word?.name === data?.priority
                              )[0]?.value || "N/A"}
                            </p>
                          </div>
                          <div className="border-b flex justify-between">
                            <p className="text-xs text-left p-2 py-4 mb-0 font-bold">
                              Source
                            </p>
                            <p className="text-xs text-left p-2 py-4 mb-0">
                              {dataSource?.filter(
                                (word) => word?.name === data?.source
                              )[0]?.value || "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="p-5 border bg-white">
                          <h3>Contact Details</h3>
                          <div className="border-b flex justify-between">
                            <p className="text-xs text-left p-2 py-4 mb-0 font-bold">
                              Email
                            </p>
                            <Paragraph
                              className="text-xs text-left p-2 py-4 mb-0"
                              copyable={{
                                text: `${data?.email}`,
                              }}
                            >
                              {data?.email || "N/A"}
                            </Paragraph>
                          </div>
                          <div className="border-b flex justify-between">
                            <p className="text-xs text-left p-2 py-4 mb-0 font-bold">
                              Phone
                            </p>
                            <p className="text-xs text-left p-2 py-4 mb-0">
                              {data?.phone || "N/A"}
                            </p>
                          </div>
                          <div className="border-b flex justify-between">
                            <p className="text-xs text-left p-2 py-4 mb-0 font-bold">
                              Address
                            </p>
                            <p className="text-xs text-left p-2 py-4 mb-0">
                              {data?.address || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="bg-white overflow-hidden border sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Activity
                          </h3>
                        </div>
                        <div className="border-t border-gray-200">
                          <dl className="mb-0">
                            <div className="bg-gray-50 border-b px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Project scope
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data?.description || "N/A"}
                              </dd>
                            </div>
                          </dl>
                        </div>
                        <form className="w-full bg-white rounded-lg px-4 flex justify-center items-center">
                          <div className="px-3 mb-2 mt-2 w-full">
                            <TextField
                              name="comment"
                              label="Comment"
                              multiline
                              rows={1}
                              required
                              fullWidth
                              placeholder="Type Your Comment"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              variant="standard"
                            />
                          </div>
                          <div className="px-3">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={submitComment}
                              type="submit"
                              endIcon={<SendOutlined style={{ fontSize: 20 }} />}
                            ></Button>
                          </div>
                        </form>
                        <div className="border-t border-gray-200">
                          {commentsData?.length > 0 ? (
                            <div
                              className="antialiased mx-auto py-1 px-2"
                              style={{
                                maxHeight: 758,
                                overflowX: "hidden",
                                overflowY: "auto",
                              }}
                            >
                              {commentsData?.map((comment, id) => (
                                <div className="space-y-4 mb-1" key={id}>
                                  <div className="flex">
                                    <div className="flex-shrink-0 mr-3">
                                      <Avatar
                                        className="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10"
                                        alt={comment?.user?.name}
                                      >
                                        {comment?.user?.name?.charAt(0) || "N/A"}
                                      </Avatar>
                                    </div>
                                    <div className="flex-1 border rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
                                      <div className="flex items-center justify-between">
                                        <div>
                                          <strong>
                                            {comment?.user?.name || "N/A"}{" "}
                                          </strong>
                                          <span className="text-xs text-gray-400">
                                            {moment(comment?.created_at).format(
                                              "MMMM Do YYYY, h:mm a"
                                            ) || "N/A"}
                                          </span>
                                        </div>
                                        <div className="text-sm text-gray-500 font-semibold">
                                          {/* <EditOutlined
                                        // onClick={() =>
                                        //   setComment(comment?.comment)
                                        // }
                                        onClick={() => editFun(comment)}
                                        className="mr-2"
                                      /> */}

                                          {user?.result?._id ===
                                            comment?.user?._id ? (
                                            <DeleteOutlined
                                              onClick={() =>
                                                deleteCommentFun(comment?._id)
                                              }
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <p className="text-sm">
                                        {comment?.comment || "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="p-4 text-center">
                              No comments found!😢
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex justify-center flex-col items-center w-full">
                  <Spin />
                </div>
              )}
            </div>
            <Modal
              title="Delete Lead"
              visible={isModalVisible}
              onOk={() => setIsModalVisible(false)}
              footer={null}
              onCancel={() => setIsModalVisible(false)}
            >
              <Typography
                color="textPrimary"
                variant="h6"
                className="text-center"
              >
                Are you sure want to delete this lead?
              </Typography>
              <p style={{ textAlign: "center" }}>
                When the lead will be deleted all lead data including comments
                <b> will be lost</b>. Be careful this action cannot be undone!
              </p>
              <div className="flex items-center justify-center mt-4">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={deleteLeadFun}
                >
                  Delete
                </Button>
              </div>
            </Modal>
          </main> :
          <div className="flex justify-center flex-col items-center w-full">
            <Spin />
            <p>Loading...</p>
          </div>}
      </Container>
    </div>
  );
};

export default ViewLead;
